import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductQuiz from '../components/ProductQuizPage/Content'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data} headerDisable={true} footerDisable={true} themeColor={'#ffffff'}>
      <SEO {...data.seo} />
      <ProductQuiz />
    </Layout>
  )
}
