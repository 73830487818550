import { states, statesAvailable, statesNotServe } from './states'

export const isValidForEp02 = (value1, value2, value3) => {
  if (statesNotServe.includes(value1)) {
    return false
  }

  if (value2 === 'exogenous') {
    return false
  }

  return true
}

export const isValidForOralTrtPlus = (value1, value2, value3) => {
  if (statesNotServe.includes(value1)) {
    return false
  }

  if (!statesAvailable.includes(value1)) {
    return false
  }

  if (value3 === 'no') {
    return false
  }

  return true
}

export const isValidForOralTrt = (value1, value2, value3) => {
  if (statesNotServe.includes(value1)) {
    return false
  }

  if (!statesAvailable.includes(value1)) {
    return false
  }

  if (value3 === 'yes') {
    return false
  }

  return true
}

export const getRecommendation = (validForEp02, validForOralTrtPlus, validForOralTrt, value1, value2, value3) => {
  if (validForEp02 && validForOralTrtPlus) {
    return 'We recommend starting with our EP-02 Protocol. If the results are not as pronounced as you\'d like, you can upgrade to Oral TRT+ at any time.'
  }

  if (validForEp02 && validForOralTrt) {
    return 'We recommend starting with our EP-02 Protocol. If the results are not as pronounced as you\'d like, you can upgrade to Oral TRT at any time.'
  }

  if (!validForEp02 && validForOralTrtPlus) {
    return 'We recommend our Oral TRT+ Protocol, it combines exogenous testosterone with Enclomiphene to help preserve fertility.'
  }

  if (!validForEp02 && validForOralTrt) {
    return 'We recommend our Oral TRT Protocol for boosting testosterone without the need for injections or creams.'
  }

  if (validForEp02 && !validForOralTrtPlus && !validForOralTrt) {
    if (value3 === 'yes') {
      if (value2 === 'exogenous') {
        return 'Unfortunately our products that provide supplemental bioidentical testosterone (Oral TRT and Oral TRT+) are not yet available in your state. If you have not been diagnosed with primary hypogonadism, we recommend trying our EP-02 Protocol, which boosts your body\'s natural testosterone production without harming fertility.'
      }

      return 'We recommend trying our EP-02 Protocol. It\'s a great solution for boosting your body\'s natural testosterone production while maintaining fertility.'
    } else {
      if (value2 === 'both') {
        return 'We recommend trying our EP-02 Protocol. It\'s a great solution for boosting your body\'s natural testosterone production. If you prefer to try Oral TRT, be sure to join our mailing list to find out when it becomes available in your state.'
      }

      if (value2 === 'exogenous') {
        return 'Unfortunately our products that provide supplemental bioidentical testosterone (Oral TRT and Oral TRT+) are not yet available in your state. If you have not been diagnosed with primary hypogonadism, we recommend trying our EP-02 Protocol, which boosts your body\'s natural testosterone production without harming fertility.'
      }

      return 'We recommend trying our EP-02 Protocol. It\'s a great solution for boosting your body\'s natural testosterone production.'
    }
  }
}



